
@media screen and (min-width: 550px) {
#krishna{
  margin-top: 50px;
}

#anil{
  color:white;
}
.web_name{
  color: darkturquoise;
  font-size: 5rem;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.web_name:hover{
  color: white;
}
.main_content{
  display: flex;
  flex-direction: column;
}
.hi{
  font-size: 4rem;
  font-family: monospace;
  color: rgb(95, 95, 95);
}
.desc{
  font-size: 4rem;
  font-family: monospace;
  color: rgb(95, 95, 95);
  margin-top: 50px;
}
.desc:hover{
  color: white
}
.hi:hover{
  color: white;
}
.space{
  height: 100px;
}
.name_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.parent{
  display: flex;
}
.nav{
  width:max-content;
  color: antiquewhite;
  padding-right:10px;
  background-color: rgb(24,24,24);
  height: 100vh;
  margin-right: 20px;
  
}
.nav_items{
  font-size: 2rem;
  font-weight: lighter;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-top: 40px;
  opacity: 50%;
  margin-left: 5px;
}
.nav_items:hover{
opacity: 1;
color: darkturquoise;
}

.ico{
  width: 70px;
  margin-left: 15px;
}
a { color: inherit;text-decoration: none; } 

.ps{
  color: green;
  font-size: x-large;
  margin-top: 40px;
}
}

@media screen and (max-width: 549px) {

}


