@media screen and (min-width: 550px) {
.pr_projects{
    background-color: rgb(29, 29, 29);
    height: max-content;
    min-height: 100vh;
}
.home_shrt{
    margin: 0;
    color: white;
    padding: 10px 0 0 10px;
    font-weight: 200;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.head_projects{
    margin: 0;
    font-weight: 300;
    padding: 50px;
    font-size: xxx-large;
    font-family: monospace
}
.projects{
    
    width: 100vw;
    display: flex;
    justify-content: center;
}
table{
    display: table-row-group;
}
table>tr{
    padding: 0;
}

.snp{
    max-width: 400px;
    border: 2px white solid;
    opacity: 0.7;
}

.head_projects{
    color: white;
    width: 100vw;
}

.actv:hover{
    opacity: 1;
}


}

@media screen and (max-width: 549px) {
    
.snp{
    max-width: 350px;
    border: 2px white solid;
    opacity: 0.7;
}

.head_projects{
    color: white;
    padding-left: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.actv:hover{
    opacity: 1;
}

table>tr{
    display: flex;
    flex-direction: column;
}
table{
    display: table-row-group;
    display: flex;
    justify-content: center;
}
.home_shrt{
    margin: 0;
    color: white;
    padding: 10px 0 0 10px;
    font-weight: 200;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.more{
    display: none;
}


}